@import 'sweco-digital-platforms/styles';

.scorepicker{
  max-width: 666px;
}
.score {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 1px #000;
}

.score:hover{
  cursor: pointer;
  box-shadow: 0 5px 15px grey;
}

.score-container{
  width: 3.2rem;
  height: 3.2rem;
}

.score-selected{
  box-shadow: 0 5px 15px grey;
  width: 3.2rem;
  height: 3.2rem;
}

.score-info{
  display: flex;
  justify-content: space-between;
  width: 71%;
  min-width: 650px;
}

@media screen and (max-width: 992px) {
  .score-info{
    display: none;
  }
}


.question-validation-error{
  box-shadow: 0 0 10px red;
}

.submit-button-container{
  display: flex;
  justify-content: end;
}